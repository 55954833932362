<template>
  <div class="d-flex ml-8">
    <div aria-hidden="true" class="iconsize">
      <v-icon color="white" class="mt-1" size="x-large">mdi-{{icon}}</v-icon>
    </div>
    <div class="d-flex flex-column">
      <h1 class="contactblokboven contactblokfont">{{ titel }}</h1>
      <h2 class="contactblokonder contactblokfont"><slot/></h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contactblok",
  props: {
    icon: String,
    titel: String,
  },

}
</script>

<style scoped>
.iconsize {
  width: 3em;
  height: 3em;
}
.contactblokboven {
  color: #d1b680;
}
.contactblokonder {
  color: white;
}
.contactblokfont {
  font-size: 1.2em;
  font-weight: bolder;
}

</style>
