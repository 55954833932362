<template>
  <div class="informatie">
    <afbeelding afbeelding="immy.jpg" titel="Immy" :tekst_links="true">
      Ik heb veel ervaring in het geven van massage en heb ook veel geleerd over het menselijk lichaam. Ik heb diverse massagediploma’s gehaald en ben een ervaren masseuse. Als u aangeeft waar u last van heeft of waar u van houdt dan kan ik u een hele fijne behandeling geven. Wilt u juist een harde of een zachte behandeling? Ik kan ervoor zorgen dat u mijn praktijk weer ontspannen verlaat. Ik wil wel benadrukken dat mijn massages niets met erotiek te maken hebben. Ik ben blij als u tevreden bent en ik ga ervan uit dat u zeker terugkomt als u mijn massages heeft ervaren.
    </afbeelding>
    <afbeelding afbeelding="behandel0.jpeg" titel="Behandelingen en tarieven" :tekst_links='false'>
      <table>
        <tr>
          <td></td>
          <th scope="col">30&nbsp;min.</th>
          <th scope="col">60&nbsp;min.</th>
          <th scope="col">90&nbsp;min.</th>
        </tr>
        <tr>
          <th scope="row" class="tabelomschrijving">Thaise massage</th>
          <td class="tabeldata">30</td>
          <td class="tabeldata">55</td>
          <td class="tabeldata">80</td>
        </tr>
        <tr>
          <th scope="row" class="tabelomschrijving">Oliemassage</th>
          <td class="tabeldata">30</td>
          <td class="tabeldata">55</td>
          <td class="tabeldata">80</td>
        </tr>
        <tr>
          <th scope="row" class="tabelomschrijving">Nek– en schoudermassage</th>
          <td class="tabeldata">30</td>
          <td class="tabeldata">55</td>
          <td class="tabeldata"></td>
        </tr>
        <tr>
          <th scope="row" class="tabelomschrijving">Hot Stone massage</th>
          <td class="tabeldata"></td>
          <td class="tabeldata">65</td>
          <td class="tabeldata">90</td>
        </tr>
        <tr>
          <th scope="row" class="tabelomschrijving">Thaise kruidenstempel</th>
          <td class="tabeldata"></td>
          <td class="tabeldata">65</td>
          <td class="tabeldata">90</td>
        </tr>
        <tr>
          <th scope="row" class="tabelomschrijving">Zwangerschaps&#8203;massage</th>
          <td class="tabeldata">30</td>
          <td class="tabeldata">55</td>
          <td class="tabeldata">80</td>
        </tr>
        <tr>
          <th scope="row" class="tabelomschrijving">Voetmassage</th>
          <td class="tabeldata">30</td>
          <td class="tabeldata">55</td>
          <td class="tabeldata"></td>
        </tr>
        <tr class="trborder">
          <th scope="row" class="tabelomschrijving">Nuad Raksa</th>
          <td class="tabeldata">30</td>
          <td class="tabeldata">55</td>
          <td class="tabeldata">80</td>
        </tr>
      </table>
      </afbeelding>
      <afbeelding afbeelding="thais.jpeg" titel="Thaise massage" :tekst_links='true'>
        Een zeer effectieve massage voor lichaam en geest op een hoog niveau. In vergelijking met andere massages is de traditionele Thaise massage een vrij harde en stevige massage, waarbij de masseur de energiebanen masseert en rek- en strekbewegingen uitvoert. Beginnend bij de voeten en eindigend bij uw hoofd zal uw lichaam worden bewogen, losgemaakt en uitgerekt. Ik kan mijn technieken aanpassen aan uw wensen en een mildere vorm hanteren. Het doel blijft om lichaam en geest in balans te brengen.
      </afbeelding>
      <afbeelding afbeelding="behandeling.jpg" titel="Hot Stone massage" :tekst_links='false'>
        Een eeuwenoude massagetechniek met lavastenen die tot ongeveer 50 graden worden verwarmd. De stenen worden in regelmatige synchrone bewegingen over uw lichaam bewogen. Door de combinatie van warmte en beweging werkt de massage dieper in op het spierweefsel. Het zelfherstel van het lichaam zal verbeteren, afvalstoffen worden afgevoerd en uw lichaam zal meer in balans komen.
      </afbeelding>
      <afbeelding afbeelding="kruidenstempel.jpeg" titel="Thaise kruidenstempel" :tekst_links='true'>
        Bij deze massage maak ik gebruik van kruidenstempels. Dit zijn warme bundeltjes gevuld met speciale oosterse kruiden. De kruiden zorgen ervoor dat uw lichaam ontspant en wordt gereinigd. Door de heerlijke geur zal ook uw geest ontspannen.
      </afbeelding>
      <afbeelding afbeelding="voet.webp" titel="Voetmassage" :tekst_links='false'>
        Na uw voeten te ontspannen in een voetbadje zal ik uw voeten kneden en bepaalde drukpunten behandelen. Voetmassage werkt niet alleen ontspannend voor uw voeten maar zal ook andere klachten doen verminderen. Ik zorg ervoor dat blokkades worden opgeheven en dat u zich weer kunt ontspannen.
      </afbeelding>
      <afbeelding afbeelding="nuadraksa.jpeg" titel="Nuad Raksa" :tekst_links='true'>
        Dit is een Thaise massage die gericht is op een bepaald probleem. Heeft u last van uw knie, uw arm, uw schouder of uw hele lichaam? Ik ga het probleem gericht te lijf. Deze massage kan wel pijnlijk zijn, maar u zult zich snel beter voelen.
      </afbeelding>
      <afbeelding afbeelding="zwanger.jpeg" titel="Zwangerschapsmassage" :tekst_links='false'>
        Een ontspannende massage met een kalmerende en rustgevende werking. U en uw kind zullen heerlijk gemasseerd worden met speciale oliën.
      </afbeelding>
      <afbeelding afbeelding="nek.jpeg" titel="Nek– en schoudermassage" :tekst_links='true'>
        Met mijn speciale massagetechnieken zal ik uw pijnlijke en stijve spieren losmasseren. Deze massage werkt goed tegen hoofdpijn, rugpijn of een stijve nek. Ik zorg ervoor dat uw lichaamsfuncties verbeteren en dat vermoeidheid en stress zullen afnemen.
      </afbeelding>
      <afbeelding afbeelding="olie.jpeg" titel="Oliemassage" :tekst_links='false'>
        Een ontspannende massage die ervoor zorgt dat uw bloedsomloop verbetert. Overtollige spanning en afvalstoffen zullen uw lichaam verlaten. Met mijn  techniek en olie zorg ik ervoor dat uw spieren worden losgemaakt en dat u zich weer beter gaat voelen.
      </afbeelding>
      <afbeelding afbeelding="luechai6logos.png" titel="Wat denken anderen" :tekst_links='true'>
        Check wat anderen over ons hebben gezegd (de link hieronder opent een nieuwe pagina)
        <br>
        <a target="_blank"  href="https://www.google.com/maps/place/Luechai+Thai+Massage/@52.120361,4.6508696,17z/data=!4m7!3m6!1s0x47c5dbfc404aa8db:0x7fede8c49b5271aa!8m2!3d52.120361!4d4.6508696!9m1!1b1#:~:text=Write%20a-,review,-Sort">Reviews van Luechai op Google maps</a>
      </afbeelding>

    <v-container fluid style="background-color: #8a8d94">
      <div class="pageheight pt-sm-16 text-right versietekst">
        1.0.12
      </div>
    </v-container>
  </div>
</template>
<script>
import Afbeelding from "@/components/Afbeelding";
export default {
  name: 'Over',
  components: {Afbeelding},
}
</script>
<style scoped>
.informatie {
  background-color: white;
  color: gold;
}
.versietekst {
  font-size: .5em;
  color: #b9b9b9;
}
h1 {
  font-weight: bold;
  font-size: 2.5em;
}
table, td {
  padding: 2px;
  color: white;
}
tr {
  border: 1px solid lightgrey;
}
.trborder {
  border: 1px solid lightgrey;
}
.tabelomschrijving {
  text-align: right;
  padding-right: .5em;
}
.tabeldata {
  border: 1px solid #d1b680;
  border-radius: 5px;
  text-align: center;
}
.pageheight {
  height:calc(100vh - 572px)
}

</style>

