import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                background: "#000000",
                primary: '#FFD700',
                secondary: '#888888',
                accent: '#00FF00',
                error: '#0000FF',
            },
        },
    },
});
