import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import * as colors from "vuetify";

Vue.config.productionTip = false

new Vue({
  theme: {
    themes: {
      light: {
        background: colors.red, // Not automatically applied
      },
      dark: {
        background: colors.green, // If not using lighten/darken, use base to return hex
      },
    },
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
