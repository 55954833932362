<template>
  <div>
    <v-container aria-hidden="true" fluid class="backgroundwhite strokeheight">
    </v-container>
    <v-container fluid class="backgroundgrey image_stroke_height">
      <v-container>
        <v-row class="justify-space-around">
          <v-col cols="5" class="image_width order-1 align-self-center">
            <v-img class="image_height image_border" :src="require('@/assets/' + afbeelding + '')" alt=""></v-img>
          </v-col>
          <v-col col="7" class="tekst_width" :class="getOrder">
            <h1 class="titel_kleur">{{titel}}</h1>
            <h2 class="tekst_kleur tekst_grootte">
              <slot></slot>
            </h2>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Afbeelding',

  props: {
    afbeelding: String,
    titel: String,
    tekst_links: Boolean
  },
  computed: {
    getOrder() {
      console.log("getOrder");
      console.log(this.tekst_links);
      console.log(this.$vuetify.breakpoint.name);
      if (this.tekst_links || this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'md') {
        return "order-0";
      }
      return "order-2";
    }
  }
}
</script>
<style scoped>
.strokeheight {
  height: 3vh;
}
.image_stroke_height {
  min-height: 35vh;
}
.image_height {
  height: calc(30vh + 10px);
  max-height: 520px;
  min-height: 220px;
  margin-top: -12px;
}
.image_width {
  transition: transform .35s;
  transition-timing-function: ease-in-out;
  width: 30vw;
  min-width: 300px;
}
.image_width:hover {
  transform: scale(1.05);
}
.tekst_width{
  min-width: 319px;
}
.image_border {
  border: 8px solid white;
}
.backgroundwhite {
  background-color: white;
}
.backgroundgrey {
  background-color: #8a8d94;
}
.titel_kleur {
  color: #d1b680;
}
.tekst_kleur {
  color: white!important;
}
.tekst_grootte {
  font-size: 1em;
  font-weight: normal;
}
</style>
